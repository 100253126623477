import { isBrowser } from '../environment';
export function isOnline() {
  if (isBrowser()) {
    return window.navigator.onLine;
  }
  return true;
}
export function isOffline() {
  return !isOnline();
}
