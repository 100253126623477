export function backoff(params) {
  var random = Math.random() + 1;
  var _a = params.minTimeout,
    minTimeout = _a === void 0 ? 500 : _a,
    _b = params.factor,
    factor = _b === void 0 ? 2 : _b,
    attempt = params.attempt,
    _c = params.maxTimeout,
    maxTimeout = _c === void 0 ? Infinity : _c;
  return Math.min(random * minTimeout * Math.pow(factor, attempt), maxTimeout);
}
